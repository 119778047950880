<template>
    <BT-Blades
        v-if="currentLocation != null"
        :anchorBlades="[{ bladeName: 'journeys-menu' }, { bladeName: 'journeys-map' }]"
        bladeClass="pa-2"
        @loaded="noteBladesData">
        <template v-slot="bladesData">
            <BT-Snack v-model="msg" key="1" />

            <BT-Blade-List
                addBladeName="journey"
                :bladesData="bladesData"
                bladeName="journeys-menu"
                :canAdd="false"
                :canClose="false"
                canSearchLocal
                dense
                :getParams="() => { return { departureLocationID: currentLocation.id } }"
                key="2"
                navigation="journeys"
                :onPullSuccessAsync="pullJourneys"
                :refreshToggle="refreshJourneysToggle"
                :searchProps="['journeyName']"
                selectBladeName="journey-optimizer"
                :tile="false"
                title="Journeys"
                :actualUsedHeight="160"
                :defaultBladeWidth="275">
                <template v-slot:actions>
                    <v-btn v-if="showJourneyPointers" icon @click="hideJourneyPointers" title="Hide All">
                        <v-icon>mdi-circle-off-outline</v-icon>
                    </v-btn>
                </template>
                <template v-slot:toolbar-right="{ add }">
                    <BT-Menu
                        icon="mdi-plus"
                        :offset-x="false"
                        title="New Journey">
                        <template v-slot>
                            <v-list-item @click.stop="newJourneyByTemplate">
                                <v-list-item-content>New Journey</v-list-item-content>
                            </v-list-item>
                            <v-list-item @click.stop="add">
                                <v-list-item-content>New Custom Journey</v-list-item-content>
                            </v-list-item>
                        </template>
                    </BT-Menu>
                </template>
                <template v-slot="{ item }">
                    <v-list-item-avatar>
                        <v-btn v-if="showJourneyPointers" small icon @click.stop="item.showArea = !item.showArea" title="Show Area">
                            <v-icon small :color="item.showArea ? 'primary' : 'grey'">mdi-circle</v-icon>
                        </v-btn>
                        <v-icon :class="item.isReady ? 'success--text' : null" title="Ready" small>mdi-check-decagram</v-icon>
                        <v-icon :class="item.startedOn != null ? 'success--text' : null" title="Started" small>mdi-truck-check</v-icon>
                    </v-list-item-avatar>
                    <v-list-item-content>
                        <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.dueStartOn | toDayDate }}</v-list-item-subtitle>
                    </v-list-item-content>
                </template>
            </BT-Blade-List>
            
            <Journey-Blade :bladesData="bladesData" key="3" @saved="refreshJourneysToggle = !refreshJourneysToggle" />

            <Journey-Optimizer-Blade
                :bladesData="bladesData"
                @journeyDeleted="refreshJourneysToggle = !refreshJourneysToggle"
                key="4"
                :refreshToggle="refreshJourneyOptimizerToggle"
                @pulled="selectJourney"
                @saved="refreshJourneysToggle = !refreshJourneysToggle"
                @selectMovement="selectMovement"
                @pathChanged="onPathChanged" />

            <BT-Blade-Items
                bladeName="movement-courier-orders"
                :bladesData="bladesData"
                customURL="/GetCourierOrders/{id}"
                :dense="false"
                hideHeader
                navigation="courier-movements"
                :refreshToggle="refreshCourierOrdersToggle"
                showList
                title="Courier Orders"
                twoLine
                :defaultBladeWidth="275">
                <template #listItem="{ item }">
                    <v-list-item-content>
                        <v-list-item-title>{{ item.destinationLocation.companyAccount.companyName }}</v-list-item-title>
                        <v-list-item-subtitle>{{ item.destinationLocation | toLocationLine }}</v-list-item-subtitle>
                        <v-list-item-subtitle>
                            <v-row no-gutters>
                                <span>#{{ item.courierOrderNumber }}</span>
                                <v-spacer />
                                <span>{{ item.dueDate | toDayShortDate }}</span>
                            </v-row>
                        </v-list-item-subtitle>
                    </v-list-item-content>
                    <v-list-item-action class="mouse-over-show">
                        <Juncture-Builder-Btn
                            :courierOrder="item"
                            @saved="onPathChanged" />
                    </v-list-item-action>
                </template>
            </BT-Blade-Items>

            <BT-Blade
                :bladesData="bladesData"
                bladeName="journeys-map"
                :canClose="false"
                :canPin="false"
                :canMinimize="false"
                :canRefresh="false"
                flexColumn
                key="5"
                :loadingMsg="loadingMsg"
                title="Map">
                <template v-slot:toolbar-left>
                    <v-btn class="mx-2" icon @click="showDeliveryBox = true" title="Unassigned Deliveries">
                        <BT-Badge
                            icon="mdi-truck-fast"
                            :itemProperties="null"
                            navigation="courier-movements"
                            :params="{ filterBy: 'With No Journeys,Pending,Not Pickups', departureLocationID: currentLocation.id }"
                            :refreshToggle="refreshUnassignedToggle" />
                    </v-btn>
                    <v-btn class="mx-2" icon @click="showPickupBox = true" title="Pickups">
                        <BT-Badge
                            icon="mdi-clipboard-arrow-up"
                            navigation="releases"
                            :params="{ filterBy: 'Pending,Pickups', departureLocationID: currentLocation.id }"
                            :refreshToggle="refreshPickupToggle" />
                    </v-btn>

                    <v-btn class="mx-2" icon :to="{ name: 'delivery-review-hub' }" title="Flagged Deliveries">
                        <BT-Badge
                            icon="mdi-flag"
                            navigation="courier-movements"
                            :params="{ filterBy: 'Flagged,With No Journeys', departureLocationID: currentLocation.id }"
                            title="Flagged Deliveries" />
                    </v-btn>
                    <v-btn class="mx-2" icon @click="findDelivery" title="Find Delivery">
                        <v-icon>mdi-magnify</v-icon>
                    </v-btn>
                </template>
                <template v-slot:toolbar-right>
                    <BT-Menu
                        :icon="null"
                        openOnHover
                        small
                        text="Hide/Show"
                        title="Hide/Show">
                        <template v-slot>
                            <v-list-item :class="showJourneyPointers ? 'success--text' : ''" small @click="toggleJourneyPointers">
                                <v-list-item-icon><v-icon v-if="showJourneyPointers">mdi-check</v-icon></v-list-item-icon>
                                Show Journey Coverage
                            </v-list-item>
                            <v-list-item :class="showDestinations ? 'success--text' : ''" small @click="showDestinations = !showDestinations">
                                <v-list-item-icon><v-icon v-if="showDestinations">mdi-check</v-icon></v-list-item-icon>
                                Show Destination Markers
                            </v-list-item>
                            <v-list-item :class="showDestinationLines ? 'success--text' : ''" small @click="showDestinationLines = !showDestinationLines">
                                <v-list-item-icon><v-icon v-if="showDestinationLines">mdi-check</v-icon></v-list-item-icon>
                                Show Destination Lines
                            </v-list-item>
                            <v-list-item :class="showUnassignedMarkers ? 'success--text' : ''" small @click="toggleUnassignedMarkers">
                                <v-list-item-icon><v-icon v-if="showUnassignedMarkers">mdi-check</v-icon></v-list-item-icon>
                                Show Unassigned Destination Markers
                            </v-list-item>
                        </template>
                    </BT-Menu>
                </template>
                <template>
                    <GmapMap
                        :center="currentLocation"
                        :zoom="mapZoom"
                        ref="map"
                        style="width: 100%; height: calc(100vh - 112px);"
                        :options="{
                            zoomControl: true,
                            mapTypeControl: false,
                            scaleControl: false,
                            streetViewControl: false,
                            rotateControl: false,
                            fullscreenControl: false,
                            disableDefaultUi: false }">

                        <gmap-polygon
                            v-for="(pointer, index) in filteredPointers"
                            @click="selectJourneyPointer(pointer)"
                            clickable
                            :fillOpacity="0.5"
                            :key="index + 'a'"
                            :paths="pointer.boundary"
                            :strokeWeight="2" />

                        <gmap-polyline
                            v-if="currentJourney != null && currentJourney.movements != null"
                            key="line"
                            :path="filteredJourneyLines" />

                        <gmap-marker
                            v-for="(position, index) in filteredJourneyLocationMarkers"
                            @click="selectDestination(position)"
                            clickable
                            :icon="{ anchor: { x: 13, y: 23 }, path: pinSVG(), fillOpacity: 1, fillColor: position.deliveredOn != null ? '#4CAF50' : '#FF5252' }"
                            :position="position"
                            :key="index + 'b'" />

                        <gmap-marker
                            v-for="(position, index) in filteredUnassignedLocations"
                            @click="selectDestination(position)"
                            :position="position"
                            :key="index + 'a'" />

                        <gmap-info-window
                            :opened="selectedInfo != null"
                            :options="windowOptions"
                            :position="infoPosition"
                            @closeclick="selectedInfo = null"
                            key="1k">
                            <template v-slot:default>
                                <v-card v-if="selectedInfo != null && selectedInfo.lat != null && selectedInfo.lng != null" class="transparent primary--text pa-0 ma-0">
                                    <v-card-title v-if="selectedInfo.companyAccount != null" class="primary--text">
                                        {{ selectedInfo.companyAccount.companyName }}
                                    </v-card-title>
                                    <v-card-title v-else class="primary--text">{{ selectedInfo.locationName }}</v-card-title>
                                    <v-card-subtitle class="primary--text">{{ selectedInfo | toLocationLine }}</v-card-subtitle>
                                </v-card>
                                <v-card v-else-if="selectedInfo != null && selectedInfo.startLocation != null" class="transparent primary--text pa-0 ma-0">
                                    <v-card-title class="primary--text">{{ selectedInfo.journeyName }}</v-card-title>
                                    <v-card-subtitle class="primary--text">{{ selectedInfo.location | toFamiliarLocationLine }}</v-card-subtitle>
                                </v-card>
                                <v-card v-else-if="selectedInfo != null" class="transparent primary--text pa-0 ma-0">
                                    <v-card-title class="primary--text">
                                        <v-edit-dialog>
                                            <span>{{ selectedInfo.pointerName || 'Pointer' }}</span>
                                            <template v-slot:input>
                                                <v-text-field
                                                    v-model="selectedInfo.pointerName"
                                                    single-line />
                                            </template>
                                        </v-edit-dialog>
                                    </v-card-title>
                                    <v-card-subtitle class="primary--text">{{ selectedInfo.location | toLocationLine }}</v-card-subtitle>
                                    <v-card-text class="ma-0 pa-0">
                                        <BT-Chips-Menu
                                            v-model="selectedInfo.weekdays"
                                            column
                                            isEditing
                                            :items="weekdayOptions()"
                                            multiple
                                            :openOnHover="false"
                                            returnCSV
                                            xSmall />
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-btn v-if="selectedInfo.isDestination != 'undefined' && $BlitzIt.tracker.isChanged(selectedInfo)" class="primary" block @click="saveSupplyPointer(selectedInfo)">Save</v-btn>
                                    </v-card-actions>
                                </v-card>

                            </template>
                        </gmap-info-window>

                    </GmapMap>
                    
                </template>
            </BT-Blade>

            <vue-draggable-resizable v-if="currentJourneyID != null && showJourneyPointers && !hidePointerWindow" :x="300" :y="100" :h="500" :w="480" :z="2" key="6" :resizable="false">
                <BT-Blade
                    :activeClass="false"
                    :canAdd="false"
                    canClose
                    :canRefresh="false"
                    hideBackButton
                    title="Coverage">
                    <template v-slot:toolbar-right>
                        <v-btn small icon @click="hidePointerWindow = true"><v-icon>mdi-close</v-icon></v-btn>
                    </template>
                    <template>
                        <v-list class="overflow-y-auto" style="height: calc(500px - 96px)">
                            <template v-for="(item, index) in currentPointers">
                                <BT-Entity
                                    :key="index"
                                    ignorePermissions
                                    isEditing
                                    :item="item"
                                    navigation="journey-pointers">
                                    <template v-slot="{ item }">
                                        <v-list-item>
                                            <v-list-item-content>
                                                <v-list-item-title>
                                                    <v-row class="ma-0 pa-0">
                                                        <BT-Field-String
                                                            v-model="item.pointerName"
                                                            dense
                                                            hide-details
                                                            label="Name" />
                                                        <v-spacer />
                                                    </v-row>
                                                </v-list-item-title>
                                                <v-list-item-subtitle class="text-center">
                                                    <BT-Chips
                                                        v-model="item.weekdays"
                                                        :items="weekdayOptions()"
                                                        multiple
                                                        returnCSV />
                                                </v-list-item-subtitle>
                                            </v-list-item-content>
                                        </v-list-item>
                                    </template>
                                </BT-Entity>
                                <v-divider :key="index + 'd'" />
                            </template>
                        </v-list>
                    </template>
                </BT-Blade>
            </vue-draggable-resizable>

            <vue-draggable-resizable v-if="showDeliveryBox" :x="300" :y="110" :h="500" :w="400" :z="2" key="7" :resizable="false">
                <BT-Blade-Items
                    :canExportCSV="false"
                    canSearchLocal
                    hideBackButton
                    :loadingMsg="loadingMsg"
                    navigation="courier-movements"
                    :onPullSuccessAsync="pullMovements"
                    :params="{ filterBy: 'With No Journeys,Pending,Not Pickups', departureLocationID: currentLocation.id }"
                    :refreshToggle="refreshUnassignedToggle"
                    :searchProps="['dueArrivalOn','destinationLocation.companyAccount.companyName','destinationLocation.addressLineOne','destinationLocation.suburb','destinationLocation.state','destinationLocation.streetName','destinationLocation.streetNumber','destinationLocation.postcode','bundleName']"
                    :showFilters="false"
                    showList
                    title="Unassigned"
                    :actualUsedHeight="500">
                    <template v-slot:toolbar="{ items }">
                        <v-toolbar-title>Unassigned {{ items.length > 0 ? `(${items.length})` : null }}</v-toolbar-title>
                    </template>
                    <template v-slot:toolbar-right-far>
                        <v-btn small icon @click="showDeliveryBox = false">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:body="{ items, allItems }">
                        <v-list class="overflow-y-auto" style="height: calc(500px - 96px)">
                            <v-list-item-group>
                                <drop-list
                                    accepts-type="delivery"
                                    style="min-height: 200px;"
                                    @insert="d => { insertMovement(d, allItems) }"
                                    :items="items">
                                    <template v-slot:item="da">
                                        <drag
                                            :key="da.item.id"
                                            :data="da.item"
                                            @copy="allItems.splice(da.index, 1)"
                                            type="delivery">
                                            <v-list-item dense @click="selectMovement(da.item)" class="mouse-item">
                                                <v-list-item-content v-if="da.item != null && da.item.destinationLocation != null" :title="da.item.destinationLocation.suburb">
                                                    <v-list-item-title>{{ da.item.bundleName }}{{ da.item.bundleName != null ? ' - ' : null }}{{ da.item.destinationLocation.companyAccount.companyName }}</v-list-item-title>
                                                    <v-list-item-subtitle>{{ da.item.destinationLocation | toLocationLine }}</v-list-item-subtitle>
                                                    <v-list-item-subtitle>{{ da.item.dueArrivalOn | toDayShortDate }}</v-list-item-subtitle>
                                                </v-list-item-content>
                                                <v-list-item-action class="mouse-over-show">
                                                    <v-row dense>
                                                        <Juncture-Builder-Btn
                                                            :movement="da.item"
                                                            @saved="onPathChanged">
                                                            <template #activator="{ open }">
                                                                <v-btn
                                                                    icon
                                                                    class="ma-1"
                                                                    @click="open"
                                                                    small
                                                                    title="Change Path">
                                                                    <v-icon small>mdi-map-marker-path</v-icon>
                                                                </v-btn>
                                                            </template>
                                                        </Juncture-Builder-Btn>
                                                        <!-- <BT-Btn
                                                            @click.stop="pickupDelivery(da.item)"
                                                            icon="mdi-clipboard-arrow-up"
                                                            confirmText="Are you sure you want to change this delivery to a pickup?"
                                                            small
                                                            title="Pickup" /> -->
                                                        <BT-Select-Dialog
                                                            v-if="!da.item.isPickup"
                                                            buttonClass="ma-1"
                                                            icon="mdi-crosshairs-question"
                                                            isSingle
                                                            :itemID="da.item.id"
                                                            navigation="journey-suggestions"
                                                            :onFilter="filterJourneySuggestions"
                                                            :onPullSuccessAsync="res => { return res != null ? [res] : [] }"
                                                            returnObject
                                                            small
                                                            title="Suggest Journey"
                                                            width="300">
                                                            <template v-slot="{ item }">
                                                                <v-list-item-content>
                                                                    <v-list-item-title>{{ item.journeyName }}</v-list-item-title>
                                                                    <v-list-item-subtitle>{{ item.dueStartOn | toShortDate }}</v-list-item-subtitle>
                                                                </v-list-item-content>
                                                                <v-list-item-action>
                                                                    {{ !item.isCreated ? '*NEW' : '' }}
                                                                </v-list-item-action>
                                                            </template>
                                                        </BT-Select-Dialog>
                                                        <BT-Btn
                                                            v-if="$canEdit('customer-orders')"
                                                            class="error--text"
                                                            @click="cancelOrderAndDelivery(da.item, allItems)"
                                                            icon="mdi-cancel"
                                                            acceptText="Yes"
                                                            confirmText="Are you sure you want to cancel this order?"
                                                            small
                                                            title="Cancel" />
                                                    </v-row>
                                                </v-list-item-action>
                                            </v-list-item>
                                        </drag>
                                    </template>
                                    <template v-slot:inserting-drag-image="{ data }">
                                        <div>{{ data }}</div>
                                    </template>
                                    <template v-slot:reordering-drag-image />            
                                    <template v-slot:feedback="{ data }">
                                        <v-skeleton-loader
                                        type="list-item-avatar-three-line"
                                        :key="data.id"/>
                                    </template>
                                </drop-list>
                            </v-list-item-group>
                        </v-list>
                    </template>
                </BT-Blade-Items>
            </vue-draggable-resizable>

            <vue-draggable-resizable v-if="showPickupBox" :x="350" :y="120" :h="500" :w="400" :z="2" key="8" :resizable="false">
                <BT-Blade-Items
                    :canExportCSV="false"
                    canSearchLocal
                    hideBackButton
                    :loadingMsg="loadingMsg"
                    navigation="courier-movements"
                    :onPullSuccessAsync="pullMovements"
                    :params="{ filterBy: 'Unfulfilled,Pickups,Pending', departureLocationID: currentLocation.id }"
                    :refreshToggle="refreshPickupToggle"
                    :searchProps="['dueArrivalOn','destinationLocation.companyAccount.companyName','destinationLocation.addressLineOne','destinationLocation.suburb','destinationLocation.state','destinationLocation.streetName','destinationLocation.streetNumber','destinationLocation.postcode','bundleName']"
                    :showFilters="false"
                    showList
                    title="Pickups"
                    :actualUsedHeight="600">
                    <template v-slot:toolbar="{ items }">
                        <v-toolbar-title>Pickups {{ items.length > 0 ? `(${items.length})` : null }}</v-toolbar-title>
                    </template>
                    <template v-slot:toolbar-right-far>
                        <v-btn small icon @click="showPickupBox = false">
                            <v-icon small>mdi-close</v-icon>
                        </v-btn>
                    </template>
                    <template v-slot:body="{ items, allItems }">
                        <v-list class="overflow-y-auto" style="height: calc(500px - 96px)">
                            <v-list-item dense v-for="(item, ind) in items" :key="ind" class="mouse-item">
                                <v-list-item-content v-if="item != null && item.destinationLocation != null" :title="item.destinationLocation.suburb">
                                    <v-list-item-title>{{ item.bundleName }}{{ item.bundleName != null ? ' - ' : null }}{{ item.destinationLocation.companyAccount.companyName }}</v-list-item-title>
                                    <v-list-item-subtitle>{{ item.destinationLocation | toLocationLine }}</v-list-item-subtitle>
                                    <v-list-item-subtitle>{{ item.dueArrivalOn | toDayShortDate }}</v-list-item-subtitle>
                                </v-list-item-content>
                                <v-list-item-action class="mouse-over-show">
                                    <v-row dense>
                                        <Juncture-Builder-Btn
                                            :movement="item"
                                            @saved="onPathChanged">
                                            <template #activator="{ open }">
                                                <v-btn
                                                    icon
                                                    class="ma-1"
                                                    @click="open"
                                                    small
                                                    title="Change Path">
                                                    <v-icon small>mdi-map-marker-path</v-icon>
                                                </v-btn>
                                            </template>
                                        </Juncture-Builder-Btn>
                                        <BT-Btn
                                            bladeName="driver-hub"
                                            icon="mdi-open-in-new"
                                            :getQueryData="() => { return { mode: 'pickup', id: item.id }; }"
                                            small
                                            title="Open" />
                                        
                                        <!-- <BT-Btn
                                            @click="deliverPickup(item)"
                                            icon="mdi-truck-delivery"
                                            confirmText="Are you sure you want to change this pickup to a delivery?"
                                            small
                                            title="Deliver" /> -->
                                        <BT-Btn
                                            v-if="$canEdit('customer-orders')"
                                            @click="cancelOrderAndDelivery(item, allItems)"
                                            icon="mdi-cancel"
                                            class="error--text"
                                            acceptText="Yes"
                                            confirmText="Are you sure you want to cancel this order?"
                                            small
                                            title="Cancel" />
                                    </v-row>
                                </v-list-item-action>
                            </v-list-item>
                        </v-list>
                    </template>
                </BT-Blade-Items>
            </vue-draggable-resizable>
        
        </template>
    </BT-Blades>
</template>

<script>
import { firstBy } from 'thenby';
import { Drag, DropList } from "vue-easy-dnd";
import { getGoogleMapsAPI } from 'gmap-vue';

export default {
    name: 'Journey-Optimization-Blade',
    components: {
        BTChips: () => import('~components/BT-Chips.vue'),
        BTMenu: () => import('~components/BT-Menu.vue'),
        BTSelectDialog: () => import('~components/BT-Select-Dialog.vue'),
        Drag,
        DropList,
        JourneyBlade: () => import('~home/journeys/Journey-Blade.vue'),
        JourneyOptimizerBlade: () => import('~home/journey-optimizer/Journey-Optimizer-Blade.vue'),
        JunctureBuilderBtn: () => import('~home/courier-orders/Juncture-Builder-Btn.vue'),
        VueDraggableResizable: () => import('vue-draggable-resizable')
    },
    data: function() {
        return {
            // currentDay: null,
            bladesData: null, //for extension saving
            currentCustomer: null, //customers-dispatch item to find relevant deliveries
            currentLocation: null,
            currentJourney: null,
            currentJourneyID: null,
            currentJourneyTemplateID: null,
            customers: [],
            hidePointerWindow: true,
            infoPosition: null,
            loadingMsg: null,
            journeys: [],
            mapZoom: 9,
            movements: [],
            msg: null,
            newAddress: null,
            pointers: null,
            refreshCourierOrdersToggle: false,
            refreshJourneyOptimizerToggle: false,
            refreshJourneysToggle: false,
            refreshPickupToggle: false,
            refreshUnassignedToggle: false,
            selectedInfo: null, //From, To, Object
            showDeliveryBox: false,
            showDestinations: true,
            showDestinationLines: true,
            showJourneyPointers: false,
            showPickupBox: false,
            showUnassignedMarkers: false,
            // weekdayOptions: ['Always', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun'],
            windowOptions: {
                pixelOffset: {
                    width: 0,
                    height: -20
                }
            }
        }
    },
    computed: {
        google: getGoogleMapsAPI,
        currentPointers() {
            return this.pointers != null && this.showJourneyPointers ? this.pointers.filter(x => x.journeyTemplateID == this.currentJourneyTemplateID) : [];
        },
        days() {
            var l = [];

            this.journeys.forEach(j => {
                if (j.dueStartOn != null) {
                    var d = this.$BlitzIt.auth.formRawTZ(j.dueStartOn).startOf('day').toUTC().toString();
                    if (!l.some(z => z == d)) {
                        l.push(d);
                    }
                }
            })

            //l.push(...this.journeys.filter(x => x.dueStartOn != null).map(x => x.dueStartOn));

            this.movements.forEach(m => {
                if (m.dueDepartureOn != null) {
                    var dd = this.$BlitzIt.auth.formRawTZ(m.dueDepartureOn).startOf('day').toUTC().toString();
                    if (!l.some(z => z == dd)) {
                        l.push(dd);
                    }
                }
            })
            
            return l;
        },
        filteredJourneyLines() {
            if (!this.showDestinationLines || this.currentJourney == null || this.currentJourney.movements == null) {
                return [];
            }

            return [this.currentJourney.startLocation, ...this.currentJourney.movements.map(x => x.destinationLocation), this.currentJourney.endLocation];
        },
        filteredJourneyLocationMarkers() {
            if (!this.showDestinations || this.currentJourney == null || this.currentJourney.movements == null) {
                return [];
            }

            return [
                Object.assign({}, { ...this.currentJourney.startLocation, deliveredOn: this.currentJourney.startedOn }), 
                ...this.currentJourney.movements.map(x => Object.assign({}, { ...x.destinationLocation, deliveredOn: x.deliveredOn })), 
                Object.assign({}, { ...this.currentJourney.endLocation, deliveredOn: this.currentJourney.endedOn })
            ];
        },
        filteredPointers() {
            if (this.pointers == null || !this.showJourneyPointers) {
                return [];
            }

            var fList = this.journeys.filter(z => z.showArea && z.journeyTemplateID != null);
            return this.pointers.filter(pointer => fList.some(z => z.journeyTemplateID == pointer.journeyTemplateID));
        },
        filteredUnassignedLocations() {
            return this.showUnassignedMarkers && this.movements != null ? this.movements.map(x => x.destinationLocation) : [];
        }
    },
    async mounted() {
        if (this.currentLocation == null) {
            if (this.currentLocation == null) {
                this.currentLocation = await this.$selectItem({
                    navigation: 'locations', 
                    itemText: 'locationName', 
                    required: true });
            }
        }

        document.addEventListener('extensionSaveJourney', this.extensionSave);
    },
    beforeDestroy() {
        document.removeEventListener('extensionSaveJourney', this.extensionSave);
    },
    methods: {
        async onPathChanged() {
            console.log('path changed');
            this.loadingMsg = 'Loading';
            await this.twiddleThumbs(2000);
            this.refreshCourierOrdersToggle = !this.refreshCourierOrdersToggle;
            this.refreshJourneyOptimizerToggle = !this.refreshJourneyOptimizerToggle;
            this.refreshUnassignedToggle = !this.refreshUnassignedToggle;
            this.refreshPickupToggle = !this.refreshPickupToggle;
            this.loadingMsg = null;
        },
        toggleUnassignedMarkers() {
            this.showUnassignedMarkers = !this.showUnassignedMarkers;
            this.refocusMap();
        },
        noteBladesData(bData) {
            console.log('notes');
            console.log(bData);
            this.bladesData = bData;
        },
        extensionSave(ev) {
            console.log('ev');
            console.log(ev);
            var selectBlade = {
                bladeName: 'journey-optimizer',
                data: { 
                    id: ev.detail.journey.id,
                    mapsURL: ev.detail.url
                },
            }

            console.log(selectBlade);

            if (this.bladesData != null) {
                this.bladesData.openBlade(selectBlade);
            }
        },
        async findDelivery() {
            var cust = await this.$selectItem({
                itemText: 'buyer.companyName',
                label: 'Customers',
                navigation: 'customers-dispatch',
                params: { properties: 'ID,Buyer'},
                required: false,
                showSearch: true
            });

            if (cust != null) {
                var delivery = await this.$selectItem({
                    itemText: 'destinationLocation',
                    navigation: 'courier-movements',
                    params: { customerIDs: cust.buyer.id, properties: 'ID,DueDepartureOn,DestinationLocation,IsAutoDeliver,IsPickup,IsFulfilled,JourneyID,DeliveredOn' },
                    required: false,
                    showSearch: false,
                    subTextFilter: 'toDayShortDate',
                    subTextFunction: x => x.dueDepartureOn,
                    textFilter: 'toFamiliarLocationLine'
                });

                if (delivery != null) {
                    if (delivery.journeyID != null) {
                        //open journey and select delivery
                        this.bladesData.openBlade({
                            bladeName: 'journey-optimizer',
                            data: { id: delivery.journeyID, movementID: delivery.id },
                            query: { }
                        });
                    }
                    else if (delivery.isPickup) {
                        //open pickups box
                        this.showPickupBox = true;
                    }
                    else {
                        //open unassigned box
                        this.showDeliveryBox = true;
                    }
                }
            }
        },
        // async pickupDelivery(delivery) {
        //     try {
        //         delivery.isPickup = true;
        //         await this.$BlitzIt.store.patch('courier-movements', delivery);
        //         // this.$router.push({ name: 'driver-hub', query: { mode: 'pickup' } });
        //         this.refreshUnassignedToggle = !this.refreshUnassignedToggle;
        //         this.refreshPickupToggle = !this.refreshPickupToggle;
        //     }
        //     catch (err) {
        //         this.msg = this.extractErrorDescription(err);
        //     }
        // },
        // async deliverPickup(delivery) {
        //     try {
        //         delivery.isPickup = false;
        //         await this.$BlitzIt.store.patch('courier-movements', delivery);
        //         this.refreshUnassignedToggle = !this.refreshUnassignedToggle;
        //         this.refreshPickupToggle = !this.refreshPickupToggle;
        //     }
        //     catch (err) {
        //         this.msg = this.extractErrorDescription(err);
        //     }
        // },
        async cancelOrderAndDelivery(delivery, fList) {
            //cancel customer order
            try {
                var conRes = await this.$BlitzIt.api.get('deliveries', `/get/GetConsignmentIDs?id=${delivery.id}`, null, null);
                var allConsignmentIDs = conRes.data.data.flatMap(x => x.value);

                console.log('conIDs');
                console.log(allConsignmentIDs);

                if (this.isLengthyArray(allConsignmentIDs)) {
                    if (allConsignmentIDs.length == 1) {
                        //delete customer order
                        await this.$BlitzIt.store.get('customer-orders', allConsignmentIDs[0]);
                        await this.$BlitzIt.store.delete('customer-orders', allConsignmentIDs[0]);
                        
                        var ind = fList.findIndex(x => x.id == delivery.id);
                        if (ind >= 0) {
                            fList.splice(ind, 1);
                        }
                        
                        this.msg = 'Cancelled';
                    }
                    else {
                        this.msg = 'Cannot cancel multiple orders';
                    }
                }
            }
            catch (err) {
                this.msg = this.extractErrorDescription(err);
            }
        },
        filterJourneySuggestions(list) {
            return list.filter(y => y.endedOn == null).sort(
                firstBy(x => x.dueStartOn)
                .thenBy(x => x.isReady)
                .thenBy(x => x.destinationCount)
            );
        },
        hideJourneyPointers() {
            this.journeys.forEach(j => {
                j.showArea = false;
            });
        },
        async insertMovement(d, list) {
            try {
                this.loadingMsg = 'Inserting';
                
                // if (d.data.deliveredOn != null) {
                //     return;
                // }

                // if (d.data.journeyID != null && d.data.releasedOn != null && !await this.$confirm('This delivery has been released.  Unassigning this will unrelease the delivery.  Are you sure?')) {
                //     return;
                // }

                d.data.journeyID = null;

                var res = await this.$BlitzIt.store.patch('courier-movements', d.data);

                d.data.rowVersion = res.rowVersion;
                list.push(d.data);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        async newJourneyByTemplate() {
            try {
                var template = await this.$selectItem({
                    navigation: 'journey-templates',
                    itemText: 'journeyName',
                    required: false
                });

                if (template != null) {
                    var d = await this.$selectDate({
                        label: 'Due Date',
                        fromNow: false
                    });

                    if (d != null) {
                        this.loadingMsg = 'Creating';

                        var post = {
                            journeyName: template.journeyName,
                            journeyTemplateID: template.id,
                            dueStartOn: d,
                            dueEndOn: d,
                            driverUserID: template.driverUserID,
                            id: null,
                            rowVersion: null,
                            maxDestinations: template.maxDestinations,
                            maxWeight: template.maxWeight,
                            maxPackages: template.maxPackages,
                            startLocationID: template.startLocationID,
                            startLocation: template.startLocation,
                            endLocationID: template.endLocationID,
                            endLocation: template.endLocation
                        };

                        var res = await this.$BlitzIt.store.post('journeys', post);
                        
                        post.id = res.id;
                        post.rowVersion = res.rowVersion;

                        this.journeys.push(post);
                    }
                }
            }
            catch (err) {
                this.loadingMsg = this.extractErrorDescription(err);
            }
            finally {
                this.loadingMsg = null;
            }
        },
        pullCustomers(customers) {
            var cList = customers.map(x => {
                return Object.assign({}, { ...x, selected: false, locations: null, loadingMsg: null });
            })
            this.customers = cList;
            return this.customers;
        },
        async pullJourneys(journeys) {
            this.journeys = journeys.map(x => {
                return Object.assign({}, { showArea: true, isOpen: false, ...x });
            });

            return this.journeys;
        },
        async pullMovements(movements) {
            this.movements = movements;
            return this.movements;
        },
        removeMovement(list, index) {
            list.splice(index, 1);
        },
        async selectCustomer(customer) {
            if (customer != null) {
                customer.selected = !customer.selected;

                if (customer.selected && customer.locations == null) {
                    //pull locations
                    try {
                        customer.loadingMsg = 'Selecting Customer';
                        var policy = await this.$BlitzIt.store.get('suppliers', customer.id, null, false, null, '/Locations/Locations');
                        customer.locations = policy.possibleLocations.map(x => { return Object.assign({}, { ...x, company: customer.buyer }) });
                    }
                    finally {
                        customer.loadingMsg = null;
                    }
                    
                }
            }
        },
        refocusMap() {
            var bounds = new this.google.maps.LatLngBounds();

            this.filteredPointers.forEach(p => {
                bounds.extend(p.boundary);
            })

            this.filteredJourneyLocationMarkers.forEach(j => {
                bounds.extend(j);
            })

            this.filteredUnassignedLocations.forEach(u => {
                bounds.extend(u);
            })

            this.$refs.map.fitBounds(bounds);
        },
        selectDestination(d) {
            this.infoPosition = d;
            this.selectedInfo = d;
        },
        selectJourney(journey) {
            this.currentJourney = journey;
            this.currentJourneyID = journey.id ?? null;
            this.currentJourneyTemplateID = journey.journeyTemplateID ?? null;

            if (journey != null) {
                this.infoPosition = journey.startLocation;
                this.selectedInfo = journey;
            }

            this.refocusMap();
        },
        selectJourneyPointer(pointer) {
            if (pointer.boundary != null) {
                this.infoPosition = pointer.boundary[2];
            }
            else {
                this.infoPosition = pointer.location;
            }
            
            this.selectedInfo = pointer;
        },
        selectMovement(m) {
            this.infoPosition = m != null ? m.destinationLocation : null;
            this.selectedInfo = m != null ? m.destinationLocation : null;

            this.refocusMap();
        },
        async toggleJourneyPointers() {
            if (!this.isLengthyArray(this.pointers) && this.isLengthyArray(this.journeys)) {
                try {
                    this.pointers = await this.$BlitzIt.store.getAll(
                        'journey-pointers',
                        {
                            includeDetails: false,
                            journeyTemplateIDs: this.journeys.filter(x => x.journeyTemplateID != null).map(x => x.journeyTemplateID).toString()
                        });
                }
                finally {
                    this.showJourneyPointers = !this.showJourneyPointers;
                }
            }
            else {
                this.showJourneyPointers = !this.showJourneyPointers;
            }
        }
    }
}
</script>